<template>
  <div>
    <h4
      v-if="
        !isFetching &&
        (!getCampaignById.campaign_id || !getCampaignById.is_active)
      "
      class="mt-4 text-center"
    >
      No Campaign Found!
      <CButton
        color="link"
        style="font-weight: 700; font-size: large"
        @click="redirectToLogin"
      >
        Sign In</CButton
      >
    </h4>
    <div v-else class="page-fix" :style="!isFetching ? `background-image: url(${getBackgroundImage})`:''" :class="{'bg-cover' : !isLessDimensionImg}">
      <LoadingOverlay v-if="isFetching" />
      <CRow v-else class="m-0">
        <CCol class="p-0" id="bg-img" style="max-width: 680px">
          <img
            v-if="!isFetching"
            class="position-fixed d-none"
            :class="isTFW ? 'istfw' : ''"
            :src="getBackgroundImage"
            @load="getImageSize"
            :style="{
              width: `${getScreenSize.width - 17}px`,
              height: `${getScreenSize.height}px`,
            }"
            @error="
              $event.target.src = '/img/new_home_bg.png';
              $event.target.style = '';
            "
            ref="campaign_bg_img"
            alt="bg"
          />
        </CCol>
        <CCol class="p-0">
          <CContainer class="container" style="min-height: calc(100vh - 95px)">
            <CCardGroup
              class="d-flex card-group has-loading-overlay justify-content-center"
            >
              <CCard class="card p-4 border-0 ml-auto mr-5">
                <CCardBody>
                  <div v-if="getCampaignById.welcome_text" class="p-0">
                    <div class="row">
                      <div class="col-md-3 d-flex justify-content-center">
                        <img
                          class="img-fluid"
                          :src="getLogoImage"
                          @error="$event.target.src = '/img/tflogo.png'"
                          alt="logo"
                        />
                      </div>
                      <div class="col-md-9">
                        <h4 class="text-center">
                          {{ getCampaignById.welcome_text }}
                        </h4>
                      </div>
                    </div>
                    <hr color="text-primary" size="10" />
                  </div>
                  <h3 class="text-primary mb-2">Pre-Registration</h3>
                  <h4 class="mb-3">{{ getCampaignById.campaign_name }}</h4>
                  <hr class="bg-primary rounded-pill" style="height: 2px;" />
                  <div class="step-1" v-if="step === 1">
                    <dynamic-form
                      :dynamicFields="basicFields"
                      :candidateData="candidatedata"
                      @callBack="basicSubmit"
                      @cancelCallBack="redirectToLogin"
                      :isBasicForm="true"
                      :register_now="candidatedata.register_now"
                      v-if="!isFetching"
                    />
                  </div>

                  <div class="step-2" v-else-if="step === 2">
                    <dynamic-form
                      v-if="!isFetching"
                      :dynamicFields="additionalCampFields"
                      :candidateData="candidatedata"
                      @callBack="onSubmit"
                      @cancelCallBack="redirectToLogin"
                      :register_now="candidatedata.register_now"
                    />
                  </div>

                  <div v-else-if="step === 3">
                    <h2 class="text-primary mb-3 mt-3">Hello {{ resCandidateName | toTitleCase }}</h2>
                    <h4>
                      You have successfully registered with the 
                      <span class="text-primary">{{ getCampaignById.campaign_name }}</span>
                      <br/>
                      Please visit your personalised portal for updates
                    </h4>
                    <div class="my-2 mt-4 text-center">
                      <CButton color="primary" @click="redirectToLogin">Home</CButton>
                    </div>
                  </div>

                  <div v-else-if="step === 4">
                    <h3>
                      Your campaign registration has been saved – 
                      please complete the additional campaign questions 
                      as soon as possible.
                    </h3>
                    <div class="my-2 mt-4 text-center">
                      <CButton color="primary" @click="redirectToLogin">Home</CButton>
                    </div>
                  </div>

                  <div v-else-if="step === 5">
                    <h3>
                      To complete the registration for this campaign, you are required to
                      register with <b class="primary" >{{ getLocalOrgName }}</b> a member of the Talentfind World Network
                      - the global healthcare careers hub.
                    </h3>
                    <div class="my-2 mt-4 text-center">
                      <CButton variant="outline" color="primary mr-3" @click="redirectToLogin">Cancel</CButton>
                      <CButton color="primary" @click="redirectToRegistration">Continue</CButton>
                    </div>
                  </div>

                </CCardBody>
              </CCard>
            </CCardGroup>
          </CContainer>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DynamicForm from "@/components/reusable/DynamicForm.vue";
import { LOGIN_URL, getLocalOrgDetail } from "@/helpers/helper";
import { BASE_URL } from "@/service_urls";
import TextInput from "@/components/reusable/Fields/TextInput";
export default {
  name: "PreRegisterCampaign",
  components: { DynamicForm, TextInput },
  data: () => ({
    getLocalOrgDetail,
    orgAcronym: "",
    campaign_id: "",
    isFetching: false,
    isSubmitted: false,
    getLocalOrg: "",
    step: 1,
    preRegId: null,
    resCandidateName: "",
    width: null,
    height : null
  }),
  props: {
    candidatedata: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["getCampaignById", "getCampaignFields", "getCampaignCandidateDetails"]),
    getBackgroundImage() {
      return `${BASE_URL}${this.getCampaignById?.uuid_background_image_link_to_doc?.replace(
        "/api/v1",
        ""
      )}?file=background_image&${new Date().getTime()}`;
    },
    getLogoImage() {
      return `${BASE_URL}${this.getCampaignById?.uuid_logo_link_to_doc?.replace(
        "/api/v1",
        ""
      )}?file=logo&${new Date().getTime()}`;
    },
    getScreenSize() {
      return {
        width: screen.availWidth,
        height: screen.availHeight,
      };
    },
    isTFW() {
      return this.$route.params.org_name === "tfw";
    },
    basicFields() {
      return this.getCampaignFields.filter((v) => v.basic === true) || [];
    },
    additionalCampFields() {
      return this.getCampaignFields.filter((v) => !v.basic) || [];
    },
    isPreRegister() {
      return (
        (this.candidatedata?.is_campaign &&
          this.candidatedata?.campaign_pre_reg_candidate_id) ||
        false
      );
    },
    isRegCandidate() {
      return this.candidatedata?.candidate_uid || false;
    },
    candidateName() {
      const { first_name, sur_name } = this.candidatedata;
      return `${first_name ?? ''} ${sur_name ?? ''}`.trim();
    },
    goToRegistration() {
      let { path_acronym, customer_uid, organisation_id, location_id } = getLocalOrgDetail();
      let path = `/${path_acronym}/register/${customer_uid}`;
      if (organisation_id)
        path += `/organisation/${organisation_id}/location/${location_id}`;
      this.$router.push({ path: path })
    },
    getLocalOrgName() {
      let { path_acronym, name } = getLocalOrgDetail();
      return name || path_acronym;
    },
    isLessDimensionImg() {
      if(this.width && this.height && this.width <= 680 && this.height <= 752 ) {
        return true;
      }
      return false;
    }

  },
  methods: {
    ...mapActions([
      "fetchCampaignById",
      "fetchCampaignFields",
      "createCampaignTransactions",
      "resetCandidate",
      "setCampaignFieldsData",
    ]),
    basicSubmit(data) {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      const isRegisterNow = data?.some(
        (v) => v.field_id === 5 && v?.answer?.yes_no_response
      );
      const payload = data.map((v) => {
        return {
          campaign_field_id: v?.field_id,
          ...v.answer,
        };
      });
      let is_reg_completed = (data.is_reg_completed !== null && data.is_reg_completed !== undefined) ? data.is_reg_completed : false; 
      this.createCampTxn(payload, is_reg_completed).then((res) => {
        if (res?.status === 200) {
          this.preRegId = res?.data?.[0]?.campaign_user_id;
          this.candidatedata.campaign_pre_reg_candidate_id = res.data?.campaign_pre_reg_id;
          this.isSubmitted = false;
          if (isRegisterNow) {
            let data = this.candidatedata
            this.$store.commit("SET_CAMPAIGN_CANDIDATE_DETAILS", data);
            this.redirectToRegistration();
          } else {
            this.redirectToLogin();
          }
        }
      });
    },
    createCampTxn(payload, is_reg_completed, queryPayload = {}) {
      return this.createCampaignTransactions({
        campaign_id: this.getCampaignById?.campaign_id,
        candidate_uid: this.candidatedata?.candidate_uid,
        completed: is_reg_completed,
        payload,
        queryPayload,
      });
    },
    onSubmit(data) {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      if(this.getCampaignCandidateDetails) {
        this.preRegId = this.getCampaignCandidateDetails?.campaign_pre_reg_candidate_id
      }
      let payload = data.map((v) => {
        return {
          campaign_field_id: v?.field_id,
          ...v.answer,
        };
      });
      let queryPayload = {};
      if (this.isRegCandidate && !this.isPreRegister) {
        const { first_name, sur_name, email, phone,candidate_type } =
          this.candidatedata;
        payload = [
          ...payload,
          { campaign_field_id: 1, text_area_response: first_name },
          { campaign_field_id: 2, text_area_response: sur_name },
          { campaign_field_id: 3, text_area_response: email },
          { campaign_field_id: 4, text_area_response: phone },
          { campaign_field_id: 29, options_response: [candidate_type] },
          {campaign_field_id: 5, options_response: [true]}
        ];
      } else {
        queryPayload = {
          is_pre_registered: true,
          campaign_pre_reg_candidate_id: this.preRegId,
        };
      }
      let is_reg_completed = (data.is_reg_completed !== null && data.is_reg_completed !== undefined) ? data.is_reg_completed : false; 
      this.createCampTxn(payload, is_reg_completed, queryPayload).then((res) => {
        if (res.status === 200) {
          this.setCampaignFieldsData(payload);
          this.showMessage = true;
          this.resCandidateName = res?.data?.campaign_candidates_data?.filter(item => [1, 2].includes(item.campaign_field_id))
            .map(v => v.text_area_response)
            .join(' ');

          if(this.candidatedata.register_now) {
            let data = this.candidatedata
            this.$store.commit("SET_CAMPAIGN_CANDIDATE_DETAILS", data);
            this.redirectToRegistration();
          } 
          else {
          // Show display message based on submit or save
          this.step = 3;
          }

        }
      });
    },
    redirectToSignUp() {
      this.resetCandidate();
      let { path_acronym, customer_uid, organisation_id, location_id } =
        getLocalOrgDetail();

      let path = `/${path_acronym}/register/${customer_uid}`;
      if (organisation_id)
        path += `/organisation/${organisation_id}/location/${location_id}`;

      this.$router.push({ path: path });
    },
    redirectToLogin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    redirectToRegistration() {
      let data = this.candidatedata
      this.$store.commit("SET_CAMPAIGN_CANDIDATE_DETAILS", data);
      let { campaign_uuid } = this.getCampaignById;
      this.$store.state.regCompletedCandidate = {
        campaign_uuid,
        step: 2
      }
      return this.goToRegistration
    },
    getImageSize() {
                    const img = new Image();
                    img.src = this.$refs.campaign_bg_img?.src;
                    img.onload = () => {
                        this.width = img.width;
                        this.height = img.height;
                    };
                },
  },
  async created() {
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-WZQ0CWFD9V");
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-WZQ0CWFD9V";
    script.async = true;
    document.head.appendChild(script);

    this.getLocalOrg = await getLocalOrgDetail();
  },
  async mounted() {
    const {
      params: { org_name: pid, campaign_id: cid },
    } = this.$router.currentRoute;
    this.orgAcronym = pid || "";
    this.campaign_id = cid || "";
    let campaign = await this.fetchCampaignById(this.campaign_id);
    this.isFetching = true;
    if (campaign?.campaign_id) {
      if (campaign?.campaign_registration_required) {
        let campaignId = this.getCampaignById.campaign_id;
        let appendAction = [this.fetchCampaignFields(campaignId)];
        Promise.all(appendAction).then(() => {
          this.isFetching = false;
          // IF STARTS
          if (this.candidatedata.completed === false) {
            this.step = 5
          }
          else if(this.candidatedata?.step) {
            this.step = this.candidatedata?.step;
          }
          else {
            this.step = this.isPreRegister || this.isRegCandidate ? 2 : 1;
          };
          // END

          if (this.step === 2) {
            this.preRegId = this.candidatedata?.campaign_pre_reg_candidate_id;
          }
          if (this.isPreRegister && !this.additionalCampFields.length) {
            this.redirectToSignUp();
          }
        });
      } else {
        if (this.getLocalOrg) {
          let path = `/${this.getLocalOrg.path_acronym}/register/${this.getLocalOrg.customer_uid}`;
          this.$router.push({ path: path });
        }
      }
    } else {
      this.isFetching = false;
    }
  },
  watch: {
    "$route.name": function () {
      this.page = this.$router.currentRoute.name;
      const {
        params: { org_name: pid, campaign_id: cid },
      } = this.$router.currentRoute;
      this.orgAcronym = pid || "";
      this.campaign_id = cid || "";
    },
  },
  filters: {
    toTitleCase(data) {
      return data ? _.startCase(data) : data;
    }
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 1084px) {
  #bg-img {
    display: none;
  }
}
.container {
  .card-group {
    .card {
      max-width: 450px;
      @media (min-width: 1000px) {
        margin-top: 50px;
      }
    }
  }
}
.page-fix {
  margin-top: -22px;
  margin-right: -30px;
  margin-left: -30px;
  min-height: 100vh;
  background-color: white;
  background-repeat: no-repeat;
}
.istfw {
  padding-top: 22px;
}
.bg-cover {
  background-size: cover;
}
</style>
